<template>
  <div class="d-flex flex-column">
    <h5 class="h5-text mb-2">Отчеты по дилерским центрам</h5>
    <div class="page-block flex-column mb-6">
      <!--<div class="mb-10">
        <v-btn color="secondary" :to="`/admin/reports/lc-reports`" class="mr-3 white--text">Отчет по договору</v-btn>
        <v-btn color="secondary" outlined :to="`/admin/reports/lc-reports-ext`" class="white--text">Расширенный отчет</v-btn>
      </div>-->
      <v-row class="mt-3">
        <v-col
          cols="12"
          sm="3"
        >
          <v-autocomplete
            v-model="dealershipIds"
            :items="dealerships"
            item-text="name"
            item-value="id"
            :return-object="false"
            outlined
            label="Выберите дилерский центр"
            multiple
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        ><v-menu
          ref="menu"
          v-model="dateSearchMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              label="Дата начала отчета"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            @input="dateSearchMenu = false"
          >
          </v-date-picker>
        </v-menu>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <v-menu
            ref="menu2"
            v-model="dateSearchMenu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                label="Дата конца отчета"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="dateSearchMenu2 = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          sm="2"
        >
          <v-btn
            depressed
            color="primary"
            @click="getReport()"
            block
            :loading="loading"
          >
            Сформировать отчет
          </v-btn>
          <v-btn
            depressed
            color="primary"
            data-click="exportFile()"
            data-loading="fileLoading"
            class="mt-2"
            block
            @click="downloadReport()"
            :loading="loading"
          >
            Скачать отчет
          </v-btn>
        </v-col>
      </v-row>
      <!--<div class="text-center">
        <v-btn
          color="secondary"
          x-large
          width="250"
          depressed
          class="mx-auto"
          @click="getReport()"
        >
          Посмотреть отчет
        </v-btn>
      </div>-->
    </div>
    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="perPage"
        dense
        hide-default-footer
      ></v-data-table>
    </div>
  </div>
</template>
<script>
  import { Http } from '../../../apis/Http'
  import moment from 'moment'

  export default {
    data() {
      return {
        reportDialog: false,
        dealershipIds: [3],
        dealerships: [],
        startDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        dateSearchMenu: false,
        dateSearchMenu2: false,
        loading: false,
        headers: [],
        items: [],
        perPage: 500
      }
    },
    async created() {
      const res = await Http.get('/admin/report-dealerships')
      if (res?.data) this.dealerships = res.data
    },

    methods: {

      closeDialog() {
        this.reportHtml = ''
        this.reportDialog = false
      },

      downloadReport() {
        this.loading = true
        Http.get('/admin/download-ds-report', {
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            dealershipIds: this.dealershipIds
          },
          responseType: 'blob'
        })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            const fileName = 'ds-report-' + this.startDate + '-' + this.endDate + '.xlsx'
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
          .catch(() => {
            this.showErrorSystemNotification('Ooops!')
          })
          .finally(() => { this.loading = false })
      },

      downloadReport2() {
        this.loading = true
        Http.get('/admin/download-ds-report', {
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            dealershipIds: this.dealershipIds
          },
          responseType: 'blob'
        })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            const fileName = this.filePath.split('/').pop()
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
          .catch(() => {
            this.showErrorSystemNotification('Ooops!')
          })
          .finally(() => {
            this.loading = false
          })
      },

      getReport() {
        this.loading = true
        Http.get('/admin/get-ds-report', {
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            dealershipIds: this.dealershipIds
          }
        })
          .then(response => {
            if (response.data) {
              this.headers = response.data.headers
              this.items = response.data.items
            }
            console.log(response.data)
          })
          .catch(() => {
            this.showErrorSystemNotification('Ooops!')
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
